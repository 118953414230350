import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { SectionHeading } from "../../molecules/SectionHeading/SectionHeading";
import { ContentFrame } from "../../molecules/ContentFrame/ContentFrame";
import { Link } from "../../atoms";

export const E404 = () => {
  const { t } = useTranslation("ui");

  return (
    <div>
      <ContentFrame backgroundImage="https://resources.travellocal.com/images/404_snowbg.jpg">
        <>
          <div className="pb-3">
            <SectionHeading
              sectionHeading={t("404_title", "Uh oh, we’ve lost you")}
              variant="default"
              hideRuler
            />
          </div>
          <p className={classnames("is-size-4-desktop", "mb-5")}>
            {t(
              "404_text",
              "Sorry this page isn’t available but here’s a friendly polar bear to keep you company while you find your way again."
            )}
          </p>
          <img
            src="https://resources.travellocal.com/images/404_polarbear.jpg"
            alt={t("404_bearAlt", "Polar bear")}
            className="mb-6"
          />
          {/* not sure about allDestinations namespace: 404 or common? */}
          <Link href={t("routes:destinations", "/destinations")}>
            <div className="button">
              {t("404_allDestinations", "All Destinations")}
            </div>
          </Link>
        </>
      </ContentFrame>
    </div>
  );
};
