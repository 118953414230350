export const cleanHtml = (html: string): string => {
  // Parse the html client side
  // TODO PE-1346 not doing this for now as it's causing hydration errors - find a lib for node + browser
  //
  // if (typeof DOMParser !== "undefined") {
  //   const fragment = new DOMParser().parseFromString(html, "text/html");

  //   // Add the lazyload class to any image tags
  //   const images = fragment.getElementsByTagName("img");
  //   // eslint-disable-next-line @typescript-eslint/prefer-for-of
  //   for (let i = 0; i < images.length; i++) {
  //     const img = images[i];
  //     img.className = classnames(img.className, "lazyload");

  //     const src = img.getAttribute("data-src") ?? img.src;
  //     img.setAttribute("data-src", src);
  //     img.removeAttribute("src");
  //   }

  //   return fragment.body.innerHTML;
  // }

  // Return html server side
  return html;
};
