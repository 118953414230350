/* eslint-disable */

import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import "./BudgetFilter.scss";

interface Props {
  className?: string;
  // max step / number of maxStep
  maxStep: number;
  initialSteps?: [number, number];
  // granularity of each step to snap to
  increments?: number;
  minimumStep?: number;
  inputProps: WithRequired<JSX.IntrinsicElements["input"], "name">;
  sublabel: string;
  currencyPrefix: string;
  minLabel: string;
  maxLabel: string;
  watch: UseFormWatch<FieldValues>;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
}

const calculatePercent = (step: number, minimumStep: number, maximumStep: number) =>
  Math.max(0, Math.min(1, (step - minimumStep) / (maximumStep - minimumStep))) * 100;

/**
 * BudgetFilter atom in Forms displays a range slider which is interactable with touch and mouse
 */
export const BudgetFilter: React.FC<Props> = ({
  className = "",
  maxStep,
  initialSteps = [0, 6],
  increments = 1,
  minimumStep = 0,
  inputProps,
  sublabel,
  currencyPrefix,
  minLabel,
  maxLabel,
  watch,
  register,
  setValue,
  getValues,
}) => {
  const [values, setValues] = useState<[number, number]>(initialSteps);

  const getMinMax = useCallback(
    (val: number) => Math.min(maxStep, Math.max(minimumStep, val)),
    [maxStep, minimumStep]
  );

  const [focused, setFocused] = useState<number | null>(null);

  const updatedValues = watch(inputProps.name);
  // convert updated values from string to number and ensure within min/max range
  useEffect(() => {
    if (updatedValues && updatedValues.length === 2) {
      setValues(updatedValues?.map((val: string) => getMinMax(parseInt(val))));
    }
  }, [updatedValues?.[0], updatedValues?.[1]]);
  useEffect(() => {
    if (initialSteps && initialSteps.length === 2) {
      setValue(inputProps.name + ".0", getMinMax(initialSteps[0]));
      setValue(inputProps.name + ".1", getMinMax(initialSteps[1]));
    }
  }, [initialSteps?.[0], initialSteps?.[1]]);
  const minName = inputProps.name + ".0";
  const maxName = inputProps.name + ".1";
  const currentStepMin = +watch(minName, initialSteps[0]);
  const currentStepMax = +watch(maxName, initialSteps[1]);

  const containerRef = useRef<HTMLDivElement>(null);
  const pointerIsDown = useRef(false);
  const getClampedValue = useCallback((currentStep: number, increments: number) => {
    return Math.min(
      maxStep,
      Math.max(
        Math.round((currentStep + minimumStep * (1 - currentStep / maxStep)) / increments) *
          increments,
        minimumStep
      )
    );
  }, []);
  // the step is clamped between the minimum step and the maximum, and with granularity of increments
  const clampedCurrentStepMin = useMemo(() => {
    return getClampedValue(currentStepMin, increments);
  }, [currentStepMin, increments]);
  // percent done from 0 to 100
  const percentDoneMin = useMemo(
    () => calculatePercent(clampedCurrentStepMin, minimumStep, maxStep),
    [clampedCurrentStepMin, maxStep, minimumStep]
  );
  const clampedCurrentStepMax = useMemo(() => {
    return getClampedValue(currentStepMax, increments);
  }, [currentStepMax, increments]);
  // percent done from 0 to 100
  const percentDoneMax = useMemo(
    () => calculatePercent(clampedCurrentStepMax, minimumStep, maxStep),
    [clampedCurrentStepMax, maxStep, minimumStep]
  );

  // uses css clamp to keep the text container within the component bounds
  const textContainerRef = useRef<HTMLDivElement>(null);

  const minHandleRef = useRef<HTMLButtonElement>(null);
  const maxHandleRef = useRef<HTMLButtonElement>(null);
  // pointer event handlers on down, move and up
  // handles logic of setting the current step based on x position
  const updateCurrentStep = useCallback((x: number) => {
    const minHandleEl = minHandleRef.current;
    const maxHandleEl = maxHandleRef.current;
    let index = 0;
    if (minHandleEl && maxHandleEl) {
      const minRect = minHandleEl.getBoundingClientRect();
      const maxRect = maxHandleEl.getBoundingClientRect();
      const minX = minRect.x + minRect.width / 2;
      const maxX = maxRect.x + maxRect.width / 2;
      const minD = Math.abs(minX - x);
      const maxD = Math.abs(maxX - x);
      if (minD < maxD) {
        index = 0;
      } else {
        index = 1;
      }
    }
    const containerEl = containerRef.current;
    if (containerEl) {
      const rect = containerEl.getBoundingClientRect();
      const xDist = Math.min(1, Math.max(0, (x - rect.x) / rect.width));

      if (pointerIsDown.current) {
        setValue(inputProps.name + "." + index, (xDist * maxStep).toFixed(0));
      }
    }
  }, []);
  const handleDown = useCallback((e: MouseEvent | TouchEvent) => {
    let x = 0;
    if ("touches" in e) {
      x = e.touches[0].pageX;
    } else {
      x = e.pageX;
    }
    const hitEl = hitRef.current;
    if (hitEl) {
      pointerIsDown.current = true;
      // increases the hit area to drag along
      hitEl.style.height = "110px";
      updateCurrentStep(x);
    }
  }, []);

  const handleMove = useCallback((e: MouseEvent | TouchEvent) => {
    let x = 0;
    if ("touches" in e) {
      x = e.touches[0].pageX;
    } else {
      x = e.pageX;
    }
    updateCurrentStep(x);
  }, []);
  const handleUp = useCallback(() => {
    pointerIsDown.current = false;
    const hitEl = hitRef.current;
    if (hitEl) {
      hitEl.style.height = "";
    }
  }, []);
  const hitRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (hitRef.current && textContainerRef.current) {
      const hitEl = hitRef.current;
      const textEl = textContainerRef.current;
      [hitEl, textEl].forEach((el) => {
        el.addEventListener("mousedown", handleDown, false);
        el.addEventListener("mousemove", handleMove, false);
        el.addEventListener("touchstart", handleDown, false);
        el.addEventListener("touchmove", handleMove, false);
      });
      window.addEventListener("mouseup", handleUp, false);
      window.addEventListener("touchend", handleUp, false);

      return () => {
        [hitEl, textEl].forEach((el) => {
          el.removeEventListener("mousedown", handleDown, false);
          el.removeEventListener("mousemove", handleMove, false);
          el.removeEventListener("touchstart", handleDown, false);
          el.removeEventListener("touchmove", handleMove, false);
        });
        window.removeEventListener("mouseup", handleUp, false);
        window.removeEventListener("touchend", handleUp, false);
      };
    }
  }, []);
  const keyboardCaptureElementMinRef = useRef<HTMLInputElement>(null);
  const keyboardCaptureElementMaxRef = useRef<HTMLInputElement>(null);
  const handleKeyDown = useCallback(
    (e: any) => {
      if (typeof focused == "number") {
        let focusedIndex = focused;
        if (e.key === "Tab" && focusedIndex === 0) {
          focusedIndex = 1;
          setFocused(1);
        }
        if (["ArrowUp", "w", "ArrowDown", "s"].includes(e.key)) {
          focusedIndex++;
          focusedIndex = focusedIndex % 2;
          setFocused(focusedIndex);
        }
        const updateBasedOnKey = (currentStep: number, index: number) => {
          if (["ArrowLeft", "a"].includes(e.key)) {
            setValue(inputProps.name + "." + index, Math.max(0, currentStep - increments * 10));
          }
          if (["ArrowRight", "d"].includes(e.key)) {
            setValue(
              inputProps.name + "." + index,
              Math.min(maxStep, currentStep + increments * 10)
            );
          }
        };
        const blurOnEnter = (inputEl: HTMLInputElement | null) => {
          if (inputEl) {
            if (e.key === "Enter") {
              setFocused(null);
              if (inputEl) {
                inputEl.blur();
              }
            }
          }
        };
        if (focusedIndex === 0) {
          updateBasedOnKey(currentStepMin, 0);
          blurOnEnter(keyboardCaptureElementMinRef.current);
        } else if (focusedIndex === 1) {
          updateBasedOnKey(currentStepMax, 1);
          blurOnEnter(keyboardCaptureElementMaxRef.current);
        }
      }
    },
    [focused, currentStepMin, currentStepMax]
  );
  useEffect(() => {
    if (typeof document != "undefined") {
      document.addEventListener("keydown", handleKeyDown, false);
      return () => {
        document.removeEventListener("keydown", handleKeyDown, false);
      };
    }
  }, []);
  return (
    <div className={classNames("budget-filter", className)} ref={containerRef}>
      <div className="budget-filter__strip budget-filter__bg" style={{ width: "100%" }} />
      <div
        className="budget-filter__strip budget-filter__progress"
        style={{
          left: percentDoneMin + "%",
          width: percentDoneMax - percentDoneMin + "%",
        }}
      />
      <div className="budget-filter__drag-handle-container" style={{ left: percentDoneMin + "%" }}>
        <button
          ref={minHandleRef}
          type="button"
          className="budget-filter__drag-handle"
          onFocus={(e) => {
            e.preventDefault();
            const inputEl = keyboardCaptureElementMinRef.current;
            setFocused(0);
            if (inputEl) {
              inputEl.focus();
            }
          }}
        />
      </div>
      <div className="budget-filter__drag-handle-container" style={{ left: percentDoneMax + "%" }}>
        <button
          ref={maxHandleRef}
          type="button"
          className="budget-filter__drag-handle"
          onFocus={(e) => {
            e.preventDefault();
            const inputEl = keyboardCaptureElementMaxRef.current;
            setFocused(1);
            if (inputEl) {
              inputEl.focus();
            }
          }}
        />
      </div>
      <div ref={textContainerRef} className="budget-filter__drag-handle-text-container">
        <input
          className="budget-filter__drag-handle-input"
          value={clampedCurrentStepMin}
          onKeyDown={handleKeyDown}
          ref={keyboardCaptureElementMinRef}
        />
        <input
          className="budget-filter__drag-handle-input"
          value={clampedCurrentStepMax}
          onKeyDown={handleKeyDown}
          ref={keyboardCaptureElementMaxRef}
        />
      </div>
      <div className="budget-filter__hit" ref={hitRef} />
      <div className="budget-filter__label-container">
        <div className="budget-filter__label budget-filter__left-label">
          <>
            <div className="budget-filter__label-inner">{minLabel}</div>
            <div>
              <span className="budget-filter__label-currency">{currencyPrefix}</span>{" "}
              <span className="budget-filter__label-value">
                <input
                  className={"budget-filter__input"}
                  step={increments}
                  type="number"
                  value={values[0]}
                  min={minimumStep}
                  max={maxStep}
                  onBlur={() => {
                    let outputValue = clampedCurrentStepMin;
                    const value = values[0];
                    const otherValue = getValues(inputProps.name + "." + 0);
                    if (otherValue > value) {
                      outputValue = value;
                    }

                    setValue(inputProps.name + "." + 0, getMinMax(outputValue));
                  }}
                  onChange={(e: any) => {
                    setValue(inputProps.name + ".0", getMinMax(parseInt(e.target.value)));
                  }}
                />
              </span>
            </div>
          </>
        </div>
        <div className="budget-filter__divider" />
        <div className="budget-filter__label budget-filter__right-label">
          <>
            <div className="budget-filter__label-inner">{maxLabel}</div>
            <div>
              <span className="budget-filter__label-currency">{currencyPrefix}</span>
              <span className="budget-filter__label-value">
                <input
                  className={"budget-filter__input"}
                  step={increments}
                  type="number"
                  value={values[1]}
                  min={minimumStep}
                  max={maxStep}
                  onBlur={() => {
                    let outputValue = clampedCurrentStepMax;
                    const value = values[1];
                    const otherValue = getValues(inputProps.name + "." + 0);
                    if (otherValue < value) {
                      outputValue = value;
                    }
                    setValue(inputProps.name + "." + 1, getMinMax(outputValue));
                  }}
                  onChange={(e: any) => {
                    setValue(inputProps.name + ".1", getMinMax(parseInt(e.target.value)));
                  }}
                />
              </span>
              {percentDoneMax === 100 ? "+" : ""}
            </div>
          </>
        </div>
      </div>
      {!!sublabel && (
        <div className="budget-filter__label budget-filter__full-label subtitle is-uppercase is-family-primary is-letter-spacing-1">
          {sublabel.toUpperCase()}
        </div>
      )}
      <input type="hidden" value={clampedCurrentStepMin} {...register(minName)} />
      <input type="hidden" value={clampedCurrentStepMax} {...register(maxName)} />
    </div>
  );
};
