import React from "react";
import Image from "next/legacy/image";
import classNames from "classnames";
import { imageLoader, Stamp } from "../..";
import "./PartnerNotice.scss";

export interface partnerImage {
  url: string;
  alt: string;
}

interface Props {
  items: partnerImage[];
  heading?: string;
  content: string;
  stamp?: "on" | "off";
}

/**
 * A pullout notice to introduce the LTOs voice, usually with hints or nudges to users of what's going on or what to do next.
 *
 * @status stable
 */

export const PartnerNotice: React.FC<Props> = ({
  items = [],
  heading,
  content,
  stamp,
}) => {
  return (
    <div className="partner-notice-wrapper">
      <div
        className={classNames(
          "partner-notice is-flex is-justify-content-center is-align-items-center p-5",
          {
            "has-stamp": stamp === "on",
          }
        )}
      >
        <div className="partner-notice__images">
          {items.map((item, index) => (
            <span className="avatar" key={index}>
              <Image
                src={item.url}
                alt={item.alt}
                layout="fill"
                sizes="70px"
                objectFit="cover"
                loader={imageLoader}
              />
            </span>
          ))}
        </div>
        <div className="partner-notice__content">
          {heading && <h3 className={classNames("title mb-1")}>{heading}</h3>}
          {content && <p>{content}</p>}
        </div>
        {stamp === "on" && <Stamp type="elephant" />}
      </div>
    </div>
  );
};

PartnerNotice.defaultProps = {};

PartnerNotice.displayName = "PartnerNotice";
