import { ImageLoader } from "next/image";

/**
 * A custom loader for next/image. All uses of next/image in this project should use this loader
 *
 * It exists so that
 * 1. we can ignore this warning https://nextjs.org/docs/messages/next-image-unconfigured-host
 * 2. we can specify the base path using an environment variable (useful for Site 1)
 */
export const imageLoader: ImageLoader = ({
  config,
  src,
  width,
  quality,
}: // eslint-disable-next-line  @typescript-eslint/no-explicit-any
any) => {
  // @ts-expect-error To-do add description
  if (typeof window !== "undefined" && window.__STORYBOOK_CLIENT_API__) {
    // if running on storybook, just return the src
    return src;
  }

  if (src.endsWith(".svg") && !config.dangerouslyAllowSVG) {
    // Special case to make svg serve as-is to avoid proxying
    // through the built-in Image Optimization API.
    return src;
  }

  // config will be null when rendered in Site 1/2
  const basePath = config?.path ?? "/_next/image";

  return `${basePath}?url=${encodeURIComponent(src)}&w=${width}&q=${quality ?? 75}`;
};

export default imageLoader;
