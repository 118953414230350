/* eslint-disable */

import classnames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface Props
  extends Pick<
    React.ImgHTMLAttributes<HTMLImageElement>,
    "alt" | "className" | "height" | "width"
  > {
  type: "light" | "dark" | "colour";
  dataTrackingId?: string;
}

const defaultAlt = "TravelLocal is a Travelife Partner";

/**
 * Travelife's logo
 *
 * @status stable
 */
export const TravelifeLogo: React.FC<Props> = ({ type, alt, className, ...rest }) => {
  const { t, i18n } = useTranslation("ui");

  const imgClassName = classnames(
    "lazyload",
    "travelife-logo",
    `travelife-logo--${type}`,
    className
  );

  const altText = useMemo(() => alt ?? t("TravelifeLogo_alt", defaultAlt), [alt, i18n?.language]);

  switch (type) {
    case "light":
      var path =
        "https://resources.travellocal.com/wp/uploads/2023/06/21135036/Travelife_logo_light.svg";
      break;
    case "dark":
      var path =
        "https://resources.travellocal.com/wp/uploads/2023/06/21135047/Travelife_logo_dark.svg";
      break;
    case "colour":
      var path =
        "https://resources.travellocal.com/wp/uploads/2023/06/21135038/Travelife_logo_colour-1.svg";
      break;
    default:
      var path = "";
      break;
  }

  return <img {...rest} className={imgClassName} alt={altText} title={altText} src={path} />;
};

TravelifeLogo.defaultProps = {
  alt: defaultAlt,
};
