import { Locale } from "@travellocal/utils";
import { TFunction } from "i18next";
import { dataTrackingIds } from "./MobileMenu";

export interface LinkItem {
  visible?: boolean;
  type: "link";
  label: string;
  href: string;
  dataTrackingId?: string;
}

export interface SeparatorItem {
  visible?: boolean;
  type: "separator";
}

export interface SubmenuItem {
  visible?: boolean;
  type: "submenu";
  label: string;
  submenu: LinkItem[];
  dataTrackingId?: string;
}

export type MainMenuLink = LinkItem | SubmenuItem | SeparatorItem;

export const getMenuItems = (
  t: TFunction,
  currentLocale: Locale | undefined,
  shouldShowYourTrip: boolean,
  dataTrackingIds?: dataTrackingIds
): MainMenuLink[] => {
  const links: MainMenuLink[] = [
    {
      type: "link",
      label: t("common:pageName_index", "Home"),
      href: t("routes:index", "/"),
    },
    {
      type: "link",
      label: t("ui:DestinationDropdown_linkLabel", "Destinations"),
      href: t("routes:destinations", "/destinations"),
      dataTrackingId: dataTrackingIds?.destinations,
    },
    {
      type: "submenu",
      label: t("ui:InspirationDropdown_linkLabel", "Inspiration"),
      submenu: [
        {
          type: "link",
          label: t("common:pageName_collections", "Collections"),
          href: t("routes:collections", "/collections"),
          dataTrackingId: `${dataTrackingIds?.inspirationLinks}.collections`,
        },
        {
          type: "link",
          label: t("common:pageName_articles", "Articles"),
          href: t("routes:articles", "/articles"),
          dataTrackingId: `${dataTrackingIds?.inspirationLinks}.articles`,
        },
      ],
    },
    {
      visible: currentLocale?.startsWith("en"),
      type: "submenu",
      label: t("ui:ReimagineTravelDropdown_linkLabel", "Reimagining travel"),
      submenu: [
        {
          type: "link",
          label: t("common:pageName_reimagineOverview", "Overview"),
          href: t(
            "routes:reimagineOverview",
            "https://reimagine.travellocal.com/en"
          ),
          dataTrackingId: `${dataTrackingIds?.reimagine}.overview`,
        },
        {
          type: "link",
          label: t("common:pageName_reimagineOurCommitment", "Our commitment"),
          href: t(
            "routes:reimagineOurCommitment",
            "https://reimagine.travellocal.com/en/our-climate-commitment"
          ),
          dataTrackingId: `${dataTrackingIds?.reimagine}.commitment`,
        },
        {
          type: "link",
          label: t("common:pageName_reimagineEnvironment", "Environment"),
          href: t(
            "routes:reimagineEnvironment",
            "https://reimagine.travellocal.com/en/environmental-protection"
          ),
          dataTrackingId: `${dataTrackingIds?.reimagine}.environment`,
        },
        {
          type: "link",
          label: t(
            "common:pageName_reimaginePeopleAndSociety",
            "People & Society"
          ),
          href: t(
            "routes:reimaginePeopleSociety",
            "https://reimagine.travellocal.com/en/people-and-society"
          ),
          dataTrackingId: `${dataTrackingIds?.reimagine}.peopleAndSociety`,
        },
        {
          type: "link",
          label: t("Header_about", "About us"),
          href: t("routes:about", "/about-us"),
          dataTrackingId: `${dataTrackingIds?.reimagine}.aboutUs`,
        },
      ],
    },
    {
      visible: currentLocale?.startsWith("de"),
      type: "link",
      label: t("ui:ReimagineTravelDropdown_linkLabel", "Nachhaltig Reisen"),
      href: t(
        "routes:Articles_travelDefined",
        "/magazin?tags=reisen-neu-definiert"
      ),
      dataTrackingId: dataTrackingIds?.reimagine,
    },
    {
      type: "link",
      label: t("ui:HowItWorks_linkLabel", "How it works"),
      href: t("routes:howItWorks", "/how-it-works"),
      dataTrackingId: dataTrackingIds?.howItWorks,
    },
    {
      visible: shouldShowYourTrip,
      type: "separator",
    },
    {
      visible: shouldShowYourTrip,
      type: "link",
      label: t("ui:Header_tripLabel", "Your trip", { count: 1 }),
      href: t("routes:customerIndex", "/account/"),
    },
  ];

  return links;
};
