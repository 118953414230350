import React from "react";
import classNames from "classnames";
import { DraggingContext, Link, WPImage } from "../..";
import Image from "next/legacy/image";
import imageLoader from "../../utils/imageLoader";

import "./LocationCard.scss";

export type LocationCardProps = {
  className?: string;
  color?: string;
  href: string;
  image: Pick<WPImage, "sourceUrl" | "altText">;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => unknown;
  size?: "small";
  title: string;
  titleAlign?: "left" | "center";
  imagePriority?: boolean;
};

/**
 * Component to display a location card.
 *
 * By default the location's hero image is the main feature of the card.
 *
 */
export const LocationCard = ({
  className,
  color,
  href,
  image,
  onClick,
  size,
  title,
  titleAlign = `left`,
  imagePriority,
}: LocationCardProps) => {
  const imageSrc =
    image?.sourceUrl ||
    "https://resources.travellocal.com/images/missing-image.jpg";
  const imageAlt = image?.altText || "";

  const titleSizeClass = size === `small` ? `is-size-4` : `is-size-2`;

  const titleAlignmentClass =
    titleAlign === `center` ? `is-fullwidth has-text-centered` : null;

  const imageContainerStyle = {
    backgroundColor: color,
    maxHeight: size === `small` ? `260px` : undefined,
  };

  return (
    <DraggingContext.Consumer>
      {(props) => (
        <Link
          {...props}
          onClick={onClick}
          href={href}
          className={classNames("location-card", className)}
        >
          <div className="image-container" style={imageContainerStyle}>
            <Image
              src={imageSrc}
              alt={imageAlt}
              layout="fill"
              objectFit="cover"
              loader={imageLoader}
              sizes="(max-width: 560px) 90vw, (max-width: 790px) 40vw, (max-width: 1015px) 30vw, (max-width: 1300px) 18vw, 230px"
              priority={imagePriority}
            />
            {color && (
              <div
                className="content-container has-text-white"
                style={{ backgroundColor: color }}
              ></div>
            )}
          </div>

          <h5
            className={classNames(
              "mt-2 title",
              titleAlignmentClass,
              titleSizeClass
            )}
          >
            {title}
          </h5>
        </Link>
      )}
    </DraggingContext.Consumer>
  );
};
