import React, { useCallback } from "react";
import {
  Dropdown,
  DropdownBody,
  DropdownBodyProps,
  Link,
  Button,
} from "../../..";
import { useTranslation } from "react-i18next";
import "./InspirationDropdown.scss";

export interface InspirationDropdownProps {
  dataTrackingIdButton?: string;
  dataTrackingIdLinks?: string;
}

const InspirationClickLink = (props: { children: React.ReactNode, dataTrackingId?: string; }) => {
  const { t } = useTranslation("ui");
  return (
    <div className="inspiration-dropdown__click">
      <Button element="button" className="is-header-link is-family-primary" data-tracking-id={props?.dataTrackingId}>
        {t("InspirationDropdown_linkLabel", "Inspiration")}
      </Button>
      {props.children}
    </div>
  );
};

type Props = React.PropsWithChildren<DropdownBodyProps & InspirationDropdownProps>;

const InspirationDropdownBody: React.FC<Props> = (props) => {
  const tabIndex = props.isVisible ? 0 : -1; // If visible, allow tabbing through links
  const { t } = useTranslation("ui");

  return (
    <DropdownBody {...props} visualStyle="arrow">
      <div className="inspiration-dropdown--v2__body has-background-white">
        <div className="inspiration-dropdown--v2__leftCol">
          <ul>
            <li>
              <Link
                href={t("routes:collections", "/collections")}
                tabIndex={tabIndex}
                data-tracking-id={props?.dataTrackingIdLinks && `${props.dataTrackingIdLinks}.collections`}
              >
                {t("common:pageName_collections", "Collections")}
              </Link>
            </li>
            <li>
              <Link
                href={t("routes:articles", "/articles")}
                tabIndex={tabIndex}
                data-tracking-id={props?.dataTrackingIdLinks && `${props.dataTrackingIdLinks}.articles`}
              >
                {t("common:pageName_articles", "Articles")}
              </Link>
            </li>
          </ul>
        </div>
        <div className="inspiration-dropdown--v2__rightCol">
          <img
            src="https://resources.travellocal.com/images/dropdown/inspiration.jpg"
            width={300}
            alt=""
          />
          <div>{t("InspirationDropdown_flyoutLabel", "Inspiration")}</div>
        </div>
      </div>
    </DropdownBody>
  );
};

/**
 * Inspiration dropdown on the marketing site.
 *
 * @status stable
 */
export const InspirationDropdown: React.FC<InspirationDropdownProps> = ({ dataTrackingIdButton, dataTrackingIdLinks }) => {
  const renderBody = useCallback(
    (bodyProps: Props) => <InspirationDropdownBody {...dataTrackingIdLinks} {...bodyProps} />,
    []
  );

  const renderTrigger = (triggerProps: { children?: React.ReactNode }) => (
    <InspirationClickLink dataTrackingId={dataTrackingIdButton}>
      {triggerProps.children}
    </InspirationClickLink>
  );

  return (
    <Dropdown
      className="inspiration-dropdown"
      Trigger={renderTrigger}
      openOn="click"
      Body={renderBody}
      hasShade={false}
    />
  );
};
