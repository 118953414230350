import classnames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface Props
  extends Pick<
    React.ImgHTMLAttributes<HTMLImageElement>,
    "alt" | "className" | "height" | "width"
  > {
  type: "light" | "dark";
}

const defaultAlt = "TravelLocal is ATOL member 11445 - book with confidence.";

/**
 * TravelLocal's ATOL logo.
 *
 * We have to display it on every page of our marketing site as part of the terms of our ATOL licence.
 *
 * @status stable
 */
export const AtolLogo: React.FC<Props> = ({ type, alt, className, ...rest }) => {
  const { t, i18n } = useTranslation("ui");

  const imgClassName = classnames("lazyload", "atol-logo", `atol-logo--${type}`, className);

  const altText = useMemo(() => alt ?? t("AtolLogo_alt", defaultAlt), [alt, i18n?.language]);

  const path = `https://resources.travellocal.com/images/ATOL_logo_${type}.svg`;

  return <img {...rest} className={imgClassName} alt={altText} title={altText} src={path} />;
};

AtolLogo.defaultProps = {
  alt: defaultAlt,
};
