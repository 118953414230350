/* eslint-disable */

import React from "react";
import { useAppState } from "../../utils";
import { BCorpEn } from "./BCorpEn";
import { BCorpDe } from "./BCorpDe";

export const BCorpLogo = (): JSX.Element => {
  const { appState } = useAppState();

  const locale = appState?.locale || `en`;

  let bcorpLogo: JSX.Element | null = null;

  switch (locale) {
    case `en`:
    case `en-gb`:
      bcorpLogo = <BCorpEn />;
      break;
    case `de`:
      bcorpLogo = <BCorpDe />;
      break;
    default:
      const _exhaustiveCheck: never = locale;
      return _exhaustiveCheck;
  }

  return bcorpLogo;
};
