import classNames from "classnames";
import React from "react";
import "./SubscribeWrapper.scss";

export interface SubscribeWrapperProps {
  subscribeClassName?: string;
  subscribeColumnClassName?: string;
  subscribeContentClassName?: string;
  tfLive?: string;
  title?: string;
  bodyText?: string;
  locale?: string;
  signupSource?: string;
  country?: string;
}

export const SubscribeWrapper: React.FC<SubscribeWrapperProps> = ({
  subscribeClassName,
  subscribeColumnClassName,
  subscribeContentClassName,
  tfLive,
  title,
  bodyText,
  locale,
  signupSource,
  country,
}) => {
  const hiddenFields = `source_market=${locale === "en-gb" ? "en-GB" : locale},signup_source=${signupSource}${country ? `,source_destination=${country}` : ""}`;

  return (
    <div className={classNames("subscribe-wrapper", subscribeClassName)}>
      <section className={classNames("subscribe", subscribeColumnClassName)}>
        <div className={classNames("subscribe--content", subscribeContentClassName)}>
          <h3 className="title is-size-3 has-text-white mb-3">
            {title ?? "Reimagine travel with us"}
          </h3>
          <p>
            {bodyText ??
              "Join our newsletter for more inspiration, local expertise, and updates on how we’re making travel a force for good."}
          </p>
        </div>
        <div className="subscribe--form">
          <div className="form-container">
            <div className="form-inner">
              <div data-tf-live={tfLive} data-tf-hidden={hiddenFields} />
              <script async src="//embed.typeform.com/next/embed.js"></script>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
