import React, { useCallback } from "react";
import { useAppState } from "../..";
import {
  CurrencySelectorComponent,
  CurrencySelectorComponentProps,
} from "./CurrencySelectorComponent";
import { site1Api as api } from "@travellocal/utils";

export interface CurrencySelectorProps {
  onSelect?: CurrencySelectorComponentProps["onSelect"];
}

/**
 * CurrencySelectorComponent with AppState
 * @deprecated Site 1 will be closed down soon, this component should be deleted.
 */
export const CurrencySelector: React.FC<CurrencySelectorProps> = (props) => {
  const { appState, setCookie } = useAppState();

  const onSelect = useCallback(
    (currency: api.Currency) => {
      props.onSelect?.(currency);
      setCookie("user_currency", currency?.code);
    },
    [setCookie, props.onSelect]
  );

  return (
    <CurrencySelectorComponent
      currencies={appState?.currencies ?? []}
      value={appState?.userCurrency ?? null}
      onSelect={onSelect}
    />
  );
};
