/* eslint-disable */

import classNames from "classnames";
import React, { forwardRef, LegacyRef } from "react";
import { Icomoon, Icon } from "../../../atoms/Icon/Icon";

type TextInputProps = {
  label: string;
  helpMessage?: string;
  hasError: boolean;
  errorMessage?: string;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

import "./Text.scss";

export const TextInput = forwardRef(
  (
    { hasError = false, errorMessage, helpMessage, label, type = "text", ...props }: TextInputProps,
    ref: LegacyRef<HTMLInputElement>
  ) => {
    return (
      <div className="field">
        <label className="label">{label}</label>
        <div className="control has-icons-right">
          <input
            ref={ref}
            className={classNames("input py-2 px-3 text-input", {
              "is-danger": hasError,
            })}
            type={type}
            {...props}
          />
          {helpMessage && <div className={classNames("is-size-7 mt-1")}>{helpMessage}</div>}
          {errorMessage && (
            <span className="icon is-small is-right">
              <Icon
                icomoon={Icomoon.warning2}
                className="is-flex is-justify-content-center is-align-content-center is-flex-wrap-wrap"
              />
            </span>
          )}
        </div>
        {errorMessage && <p className="help is-danger">{errorMessage}</p>}
      </div>
    );
  }
);
