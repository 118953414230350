import classnames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface Props
  extends Pick<
    React.ImgHTMLAttributes<HTMLImageElement>,
    "alt" | "className" | "height" | "width"
  > {
  type: "light" | "dark" | "colour";
}

const defaultAlt = "TravelLocal is ABTA member P6930 - book with confidence.";

/**
 * The ABTA logo.
 *
 * @status stable
 */
export const AbtaLogo: React.FC<Props> = ({
  type,
  alt,
  className,
  ...rest
}) => {
  const { t, i18n } = useTranslation("ui");

  const imgClassName = classnames(
    "lazyload",
    "abta-logo",
    `abta-logo--${type}`,
    className
  );

  const altText = useMemo(
    () => alt ?? t("AbtaLogo_alt", defaultAlt),
    [alt, i18n?.language]
  );

  const path = `https://resources.travellocal.com/images/ABTA_logo_${type}.svg`;

  return (
    <img
      {...rest}
      className={imgClassName}
      alt={altText}
      title={altText}
      src={path}
    />
  );
};

AbtaLogo.defaultProps = {
  alt: defaultAlt,
};
