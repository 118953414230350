import React, { useState, useRef } from "react";
import "./QuickMessage.scss";
import { Button, TextArea } from "../../atoms";
import Image from "next/legacy/image";
import { imageLoader } from "../../utils/imageLoader";
import { Toast } from "../Toast/Toast";
import { IoPersonCircleOutline } from "react-icons/io5";
import { MESSAGE_STATUS } from "./constants";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface Props {
  customerName: string;
  customerImage?: string;
  customerImageAlt?: string;
  leName: string;
  leImage: string;
  leImageAlt?: string;
  backgroundColorClassname?: string;
  dataTrackingIdTextboxFocus?: string;
  dataTrackingIdSendMessage?: string;
  onButtonSendClick: (e: string) => Promise<MESSAGE_STATUS>;
}

type Message =
  | {
      status: MESSAGE_STATUS.ISSUE;
    }
  | {
      status: MESSAGE_STATUS.SUCCESS;
      message: string;
      date: string;
    };

export const QuickMessage: React.FC<Props> = ({
  customerName,
  customerImage,
  customerImageAlt,
  leName,
  leImage,
  leImageAlt,
  backgroundColorClassname,
  dataTrackingIdTextboxFocus,
  dataTrackingIdSendMessage,
  onButtonSendClick,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [message, setMessage] = useState<string>("");
  const [sentMessages, setSentMessages] = useState<Message[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };
  const handleSendMessage = async () => {
    if (message === "") {
      return null;
    }

    setIsButtonDisabled(true);
    const sentMessageStatus = await onButtonSendClick(message);

    if (sentMessageStatus === MESSAGE_STATUS.SUCCESS) {
      if (message !== "") {
        const currentDate = new Date();
        const formattedDate = `${currentDate.toLocaleDateString(i18n.language, { day: "numeric", month: "short" })} ${currentDate.toLocaleTimeString(i18n.language, { hour: "numeric", minute: "2-digit" })}`;

        setSentMessages((prevSentMessages) => [
          ...prevSentMessages,
          { status: MESSAGE_STATUS.SUCCESS, message, date: formattedDate },
        ]);
        setMessage("");
      }
    } else {
      setSentMessages((prevSentMessages) => [
        ...prevSentMessages,
        { status: MESSAGE_STATUS.ISSUE },
      ]);
    }

    setIsButtonDisabled(false);
  };

  const handleClickTextArea = () => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  };

  return (
    <div className="is-relative">
      <label className="quick-message-container-sender is-flex is-flex-direction-row-reverse mb-5">
        <div className="avatar">
          {customerImage ? (
            <Image
              src={customerImage}
              alt={customerImageAlt}
              layout="fill"
              objectFit="cover"
              loader={imageLoader}
              width={60}
              height={60}
            />
          ) : (
            <IoPersonCircleOutline size={52} className="personCircleOutline" />
          )}
        </div>
        <p className="label-you has-text-weight-semibold is-size-7 is-uppercase is-family-primary is-letter-spacing-1 has-text-right">
          {t("ui:QuickMessage_LabelYou", "You")}
        </p>
        <div
          htmlFor="textArea"
          className="text-area-wrapper-sender is-family-primary has-text-weight-normal"
          onClick={handleClickTextArea}
          data-tracking-id={dataTrackingIdTextboxFocus}>
          <TextArea
            id="textArea"
            ref={textAreaRef}
            isErrored={false}
            placeholder={t("ui:QuickMessage_Placeholder", "Hi {{name}}", {
              name: leName,
            })}
            value={message}
            onChange={handleMessageChange}
            className="text-area-classname"
          />
          <Button
            element="button"
            className="send-button is-flex"
            colour={isButtonDisabled ? "white" : "teal"}
            onClick={handleSendMessage}
            disabled={isButtonDisabled}
            data-tracking-id={dataTrackingIdSendMessage}>
            {t("ui:QuickMessage_Send", "Send")}
          </Button>
        </div>
      </label>

      <div className="line" />

      {sentMessages.map((previouslySent, index) => (
        <div className="animate-slide-in" key={index}>
          <div className="mr-7 mb-5">
            <Toast
              type={previouslySent.status}
              title={
                previouslySent.status === MESSAGE_STATUS.SUCCESS
                  ? t("ui:QuickMessage_MessageSent", "Message sent")
                  : t("ui:QuickMessage_TryAgain", "Uh oh, try that again")
              }
            />
          </div>
          {previouslySent.status === MESSAGE_STATUS.SUCCESS && (
            <div
              className={classNames(
                "quick-message-container is-flex is-flex-direction-row-reverse mb-5",
                backgroundColorClassname
              )}
              key={index}>
              <div className="avatar">
                {customerImage ? (
                  <Image
                    src={customerImage}
                    alt={customerImageAlt}
                    layout="fill"
                    objectFit="cover"
                    loader={imageLoader}
                  />
                ) : (
                  <IoPersonCircleOutline size={52} className="personCircleOutline" />
                )}
              </div>
              <p className="label-you has-text-weight-semibold is-size-7 is-uppercase is-family-primary is-letter-spacing-1 has-text-right">
                {t("ui:QuickMessage_LabelYou", "You")}
              </p>
              <div className="text-area-wrapper-sent-message is-family-primary has-text-weight-normal has-text-elephant">
                {previouslySent.message}
                <p className="sent-message-date is-flex is-align-items-flex-end is-justify-content-flex-end is-family-primary has-text-weight-normal has-text-right">
                  {t("ui:QuickMessage_SentIn", "Sent in")} {previouslySent.date}
                </p>
              </div>
            </div>
          )}
        </div>
      ))}

      <div className={classNames("quick-message-container is-flex", backgroundColorClassname)}>
        <span className="avatar">
          {leImage ? (
            <Image
              src={leImage}
              alt={leImageAlt}
              layout="fill"
              objectFit="cover"
              loader={imageLoader}
            />
          ) : (
            <IoPersonCircleOutline size={52} className="personCircleOutline" />
          )}
        </span>
        <div className={classNames("text-area-wrapper", backgroundColorClassname)}>
          <p className="text-area-wrapper-lto is-family-primary has-text-elephant has-text-weight-normal">
            {t(
              "ui:QuickMessage_LtoText",
              "Hi {{ name }}. Feel free to let me know any more details about your trip! The more you tell me the easier it will be for me to make the perfect itinerary for you.",
              {
                name: customerName,
              }
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

QuickMessage.displayName = "QuickMessage";
