import React, { useEffect, useState } from "react";
import { site1Api as api } from "@travellocal/utils";
import { useContentState } from "../..";
import { useAppState } from "../..";
import {
  CardContainerComponent,
  CardContainerComponentProps,
} from "./CardContainerComponent";

export interface CardContainerProps
  extends Omit<CardContainerComponentProps, "content" | "isLoading"> {
  /**
   * Whether to automatically fetch data on the first mount, if there is no content in the collectionKey.
   */
  autoload?: boolean;
}

/**
 * Wrapper for `<CardContainerComponent />` to fetch data to pass down as a prop for render
 *
 * @deprecated replace all uses of this Site 1 component with CardLayout
 */
export const CardContainer: React.FC<CardContainerProps> = ({
  autoload = true,
  ...props
}) => {
  const { appState } = useAppState();
  const { loadContent, contentState } = useContentState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const hasContent = !!contentState?.[props.collectionKey]?.length;
    if (!hasContent && autoload) {
      setIsLoading(true);

      const cardLimit = props.cardLimit ? props.cardLimit + 1 : 5;
      api
        .getContentPage(
          props.filters ?? {},
          -1,
          cardLimit,
          appState?.userCurrency
        )
        .then((response) => {
          if (response?.data) {
            loadContent(props.collectionKey, response.data);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  return (
    <CardContainerComponent
      key={props.collectionKey}
      content={contentState?.[props.collectionKey] ?? null}
      isLoading={isLoading}
      {...props}
    />
  );
};
