import React from "react";
import Image from "next/legacy/image";
import classNames from "classnames";
import { Stamp, WPImage } from "../..";
import imageLoader from "../../utils/imageLoader";
import "./StandardHeader.scss";

interface Props {
  backgroundImage: WPImage;
  subheading?: string;
  heading: string;
  /**
   * Must be between 1 (fully opaque) and 0 (fully transparent)
   */
  overlayOpacity: number;
  imagePriority?: boolean;
}

/**
 * A standard page header. Pretty simple - often has a background image, heading and subheading.
 *
 * @status stable
 */

export const StandardHeader: React.FC<Props> = ({
  backgroundImage,
  subheading,
  heading,
  overlayOpacity,
  imagePriority,
}) => {
  return (
    <div className={classNames("standard-header m-0 columns is-centered")}>
      <div
        className="standard-header__image"
        style={{ opacity: overlayOpacity }}
      >
        {backgroundImage?.sourceUrl && (
          <Image
            src={backgroundImage.sourceUrl}
            alt={backgroundImage.altText}
            layout="fill"
            objectFit="cover"
            loader={imageLoader}
            priority={imagePriority}
          />
        )}
      </div>
      <div className="standard-header__content column is-half is-two-thirds-tablet py-8">
        {subheading && (
          <h2
            className={classNames(
              "subtitle is-family-primary is-letter-spacing-1 has-text-weight-semibold has-text-centered has-text-white mb-6 is-size-6 is-uppercase standard-header__subheading"
            )}
          >
            {subheading}
          </h2>
        )}
        {heading && (
          <h1
            className={classNames(
              "title has-text-centered has-text-white m-0 is-size-1 standard-header__heading"
            )}
          >
            {heading}
          </h1>
        )}
      </div>
      <div className="standard-header__stamp">
        <Stamp type="light" width={"135"} />
      </div>
    </div>
  );
};

StandardHeader.defaultProps = {};

StandardHeader.displayName = "StandardHeader";
