import classNames from "classnames";
import React from "react";
import Image from "next/legacy/image";
import imageLoader from "../../utils/imageLoader";

import "./ContentFrame.scss";

interface IContentFrame {
  backgroundImage?: string;
  children: React.ReactNode;
  imagePriority?: boolean;
}

export const ContentFrame = ({ backgroundImage, children, imagePriority }: IContentFrame) => {
  return (
    <div className={classNames("content-frame")}>
      {backgroundImage && (
        <Image
          src={backgroundImage}
          layout="fill"
          objectFit="cover"
          alt=""
          quality={90}
          loader={imageLoader}
          priority={imagePriority}
        />
      )}
      <div
        className={classNames(
          "has-background-white has-text-centered is-relative",
          "content-frame__frame"
        )}
      >
        {children}
      </div>
    </div>
  );
};
