import React, { useCallback } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownProps } from "../../atoms/Dropdown/Dropdown";
import { DropdownBody, DropdownBodyProps } from "../../atoms/Dropdown/DropdownBody";
import { Icon, Icomoon, Button } from "../../atoms";
import CountrySelect, { CountrySelectProps } from "../../atoms/CountrySelect/CountrySelect";
import { LocaleOption, defaultLocales } from "../LocaleModal";

import "./LocaleSelector.scss";

export interface LocaleSelectorProps {
  className?: string;

  onClick?: CountrySelectProps["onClick"];

  /** The list of locales that can be chosen from */
  locales?: LocaleOption[];

  direction?: DropdownProps["direction"];

  dataTrackingId?: string;
}

export const LocaleSelector = ({
  locales = defaultLocales,
  onClick,
  className,
  direction = "down-right",
  dataTrackingId,
}: LocaleSelectorProps) => {
  const { t } = useTranslation("ui");
  const ariaLabel = t("LocaleSelector_ariaLabel", "Change locale");

  const dropdownBody = useCallback(
    (props: DropdownBodyProps) => (
      <DropdownBody className="locale-selector__container" visualStyle="arrow" {...props}>
        <div className="locale-selector__title">{t("LocaleSelector_title", "Your region")}</div>
        <div className="locale-selector__list">
          {locales.map((option) => (
            <CountrySelect
              key={option.label}
              size="small"
              tabIndex={props.isVisible ? 0 : -1}
              {...option}
              onClick={onClick}
              dataTrackingId={dataTrackingId}
            />
          ))}
        </div>
      </DropdownBody>
    ),
    [locales, onClick]
  );

  return (
    <Dropdown
      className={classNames("locale-selector", className)}
      hasShade={false}
      openOn="click"
      direction={direction}
      Trigger={({ children }) => (
        <div className="locale-selector__click">
          <Button
            element="button"
            className="is-link"
            aria-label={ariaLabel}
            title={ariaLabel}
            data-tracking-id={dataTrackingId}>
            <Icon
              icomoon={Icomoon.sphere}
              className={classNames("is-block")}
              style={{ fontSize: 23, position: "relative", bottom: 1 }}
            />
          </Button>
          {children}
        </div>
      )}
      Body={dropdownBody}
    />
  );
};
