import React from "react";
import isChromatic from "chromatic/isChromatic";
import Image from "next/legacy/image";
import { t } from "i18next";
import {
  DestinationOption,
  DestinationSelect,
  VideoModal,
} from "../../molecules";
import { Button, Icomoon, LogoCorner } from "../../atoms";
import { CallToAction } from "../..";
import "./HomeHero.scss";
import { imageLoader, useWindowDimensions } from "../../utils";

interface Props {
  title: string;
  subtitle: string;
  cta?: CallToAction;
  video?: {
    ctaLabel: string;
    modalAriaLabel: string;
    embedHtml: string;
  };
  destinations: DestinationOption[];
  onDestinationSelect: (destination: DestinationOption | null) => void;
}

/** This probably only works for YouTube videos */
const getEmbedWithAutoplay = (iframeHtml: string | undefined) => {
  return iframeHtml?.replace("?feature=oembed", "?feature=oembed&autoplay=1");
};

/**
 * The HomeHero component.
 *
 * N.B. The video only shows up reliably when in Canvas mode.
 */
export const HomeHero: React.FC<Props> = (props) => {
  const { width, isMobile } = useWindowDimensions();
  const showVideo = !isMobile && !isChromatic();
  return (
    <div className="home-hero hero is-relative">
      <div className="hero-video is-block">
        {showVideo ? <video
          autoPlay
          loop
          muted
          className="home-hero__bgmedia"
        >
          <source
            src="https://resources.travellocal.com/images/homepage/Home_BackgroundVideo_1080p_compressed.webm"
            type="video/webm"
          />
          <source
            src="https://resources.travellocal.com/images/homepage/Home_BackgroundVideo_1080p_compressed.mp4"
            type="video/mp4"
          />
          <img
            src="https://resources.travellocal.com/images/homepage/Home_BackgroundImage_compressed.jpg"
            alt=""
          />
        </video> : <Image
          className="home-hero__image"
          src={
            !width || width < 600
              ? "https://resources.travellocal.com/images/homepage/home_mobile_compressed.jpg"
              : "https://resources.travellocal.com/images/homepage/Home_BackgroundImage_compressed.jpg"
          }
          layout="fill"
          objectFit="cover"
          alt="TravelLocal"
          loader={imageLoader}
          priority
        />}
      </div>

      <div className="hero-body has-text-centered has-text-white">
        <div className="container py-6 is-relative">
          <h1 className="title is-size-1 has-text-white mb-3">{props.title}</h1>

          <h2 className="title is-size-3 has-text-white mb-6">
            {props.subtitle}
          </h2>

          <DestinationSelect
            className="home-hero__destinations is-inline-block"
            destinationsList={props.destinations}
            onChange={(newValue: DestinationOption | null) =>
              props.onDestinationSelect?.(newValue)
            }
            name="home-destination-select"
            placeholderVariant="elephant"
            isSearchable={true}
          />

          <div className="level is-mobile is-centered is-flex-wrap-wrap is-justify-content-center mt-6">
            {props.cta && (
              <div className="level-item m-2 is-narrow">
                <Button
                  href={props.cta.url}
                  target={props.cta.target}
                  isGhost
                  colour="white"
                >
                  {props.cta.title}
                </Button>
              </div>
            )}
            {props.video && (
              <div className="level-item m-2 is-narrow">
                <VideoModal
                  renderTrigger={(onClick) => (
                    <Button
                      element="button"
                      isGhost
                      className="is-white"
                      onClick={onClick}
                      icomoonIcon={Icomoon.playOutline}
                      iconSide="right"
                    >
                      {props.video?.ctaLabel ||
                        t<string>("HomeHero_videoCtaLabelDefault", "Play")}
                    </Button>
                  )}
                  dialogAriaLabel={props.video.modalAriaLabel}
                  renderVideo={() => (
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          getEmbedWithAutoplay(props.video?.embedHtml) ?? "",
                      }}
                    />
                  )}
                />
              </div>
            )}
          </div>
          <LogoCorner
            type="light"
            corner="tl"
            className="is-absolute is-hidden-mobile"
          />
          <LogoCorner
            type="light"
            corner="tr"
            className="is-absolute is-hidden-mobile"
          />
          <LogoCorner
            type="light"
            corner="bl"
            className="is-absolute is-hidden-mobile"
          />
          <LogoCorner
            type="light"
            corner="br"
            className="is-absolute is-hidden-mobile"
          />
        </div>
      </div>
    </div>
  );
};
