import React from "react";
import classNames from "classnames";
import isChromatic from "chromatic/isChromatic";
import Image from "next/legacy/image";
import { Button, Icomoon } from "../../atoms";
import { Breadcrumbs } from "../..";
import "./CountryHero.scss";
import { imageLoader } from "../../utils";
import { useMediaQuery } from "react-responsive";

interface Props {
  /** A user-friendly label for this destination */
  country: string;
  /** A code-friendly value, e.g. a slug or a URL. */
  value: string;
  /** The country colour - should be a hex*/
  color: string;
  /** Trusted shops value adjusted to the locale */
  trustedShops?: string;
  /** The hero image */
  heroImage: {
    src: string;
    alt: string;
  };
  /** Optional background video */
  video?: {
    webm?: string;
    mp4?: string;
  };
  /** The ID of the container to scroll to onClick */
  scrolltoID: string;
  visitCountryLabel: string;
  craftYourUniqueTripLabel: string;
  tripIdeasLabel: string;
  planYourTriplabel: string;
  breadcrumbsItemsLabels: {
    label: string;
    href?: string;
  }[];
}

/**
 * The CountryHero component.
 */
export const CountryHero: React.FC<Props> = (props) => {
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const color = props.color || "var(--brand-accent)";

  const handleClick = () => {
    const element = document.getElementById(props.scrolltoID);

    if (element) {
      window.scrollTo({
        top: element.offsetTop - 100,
        behavior: "smooth",
      });
    } else {
      console.warn(`This element #${props.scrolltoID} wasn't found`);
    }
  };

  const showImage = isMobile || isChromatic() || (!props?.video?.mp4 && !props?.video?.webm);
  const showVideo = !showImage;

  return (
    <div className="country-hero is-relative">
      <div className="is-block">
        {showImage && (
          <Image
            className="country-hero__image"
            src={props?.heroImage?.src}
            sizes="90vw"
            layout={isMobile ? "responsive" : "fill"}
            objectFit={isMobile ? undefined : "cover"}
            alt={props?.heroImage?.alt}
            loader={imageLoader}
            quality="50"
            width={1920}
            height={1080}
            priority
          />
        )}
        {showVideo && (
          <video
            autoPlay
            loop
            muted
            className={classNames("country-hero__bgmedia is-hidden-mobile")}>
            <source src={props?.video?.mp4} type="video/mp4" />
            <source src={props?.video?.webm} type="video/webm" />
            <img src={props?.heroImage?.src} alt={props?.heroImage?.alt} />
          </video>
        )}
      </div>

      <div className="container is-relative">
        <div className="column is-offset-1 is-8-tablet is-7-desktop is-6-widescreen is-5-fullhd contentContainer">
          <div className="country-hero__content">
            <div
              className={classNames("country-hero__colour", {
                "is-hidden": !isMobile,
              })}
              style={{ backgroundColor: color }}
            />
            <div className="country-hero__inner">
              <Breadcrumbs
                className="country-hero__breadcrumbs mb-5"
                items={[...props.breadcrumbsItemsLabels]}
              />

              <h1 className="title is-size-1 mb-2">{props.visitCountryLabel}</h1>

              <h2 className="is-size-5 mb-5">{props.craftYourUniqueTripLabel}</h2>

              <div className="buttons is-flex">
                <Button href={props.value} colour="teal" element="link">
                  {props.planYourTriplabel}
                </Button>
                <Button
                  colour="teal"
                  icomoonIcon={Icomoon.arrowDown3}
                  element="button"
                  onClick={handleClick}
                  isGhost>
                  {props.tripIdeasLabel}
                </Button>
              </div>

              {props.trustedShops && (
                <div className="trustedshops trusted-shops mt-2">
                  <div dangerouslySetInnerHTML={{ __html: props.trustedShops }}></div>
                </div>
              )}
            </div>
            <div
              className={classNames("country-hero__colour", {
                "is-hidden": isMobile,
              })}
              style={{ backgroundColor: color }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
