import classnames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { ContentFrame } from "../../molecules/ContentFrame/ContentFrame";
import { Logo } from "../../atoms";

export const Maintenance = () => {
  const { t } = useTranslation("ui");

  return (
    <ContentFrame backgroundImage="https://resources.travellocal.com/images/maintenance.jpg">
      <>
        <Logo width={84} className="mb-6" />

        <h1 className="mb-5 title is-3 is-family-secondary has-text-weight-light">
          {t("Maintenance_title", "We’re just doing some maintenance")}
        </h1>

        <p className={classnames("is-size-4-desktop", "mb-5")}>
          {t(
            "Maintenance_text",
            "Sorry the site isn’t available right now but we’ll be back soon! If there’s something urgent please reach out to us on blabla@travellocal.com. Thank you."
          )}
        </p>
      </>
    </ContentFrame>
  );
};
