import React from "react";
import classNames from "classnames";
import { ReviewCard, ReviewCardProps } from "../../molecules";
import { chunk } from "lodash";
import { useTranslation } from "react-i18next";
import "./ReviewsList.scss";

interface Props {
  reviews: ReviewCardProps["review"][];
  className?: string;
}

/**
 * A list of reviews.
 * @status stable
 */
export const ReviewsList: React.FC<Props> = ({ className, reviews = [] }) => {
  const { t } = useTranslation("ui");

  if (!reviews.length) {
    return null;
  }

  return (
    <div
      className={classNames(
        "reviews-list columns is-centered",
        className
      )}
    >
      <div className="column is-12 is-10-fullhd has-text-centered">
        <h3 className="subtitle is-uppercase is-family-primary is-letter-spacing-1 is-size-5 my-5">
          {t("ReviewsList_title", "What our travellers are saying")}
        </h3>
        <div className="columns is-multiline is-centered">
          {chunk(reviews, 3).map((group) =>
            group.map((review, index) => (
              <div key={index} className={classNames("column is-4")}>
                <ReviewCard review={review} />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
