import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Dialog } from "../Dialog";
import { Trans } from "react-i18next";
import Image from "next/legacy/image";

import "./SuccessModal.scss";
import { Button } from "../../atoms";
import { imageLoader } from "../../utils";
import classNames from "classnames";

interface SuccessModalProps {
  imageText?: string;
  subtitle?: string;
  title: string;
  ctaText: string;
  getInTouchText?: string;
  imageSrc?: string;
  imageAlt?: string;
  modalAriaLabel?: string;
  isOpen?: boolean;
  onClose: () => void;
  className?: string;
  dataTrackingId?: string;
}

export const SuccessModal: React.FC<SuccessModalProps> = ({
  imageText,
  subtitle,
  title,
  ctaText,
  getInTouchText,
  imageSrc,
  imageAlt,
  modalAriaLabel,
  isOpen = true,
  onClose,
  dataTrackingId,
  className,
}) => {
  if (!title || !ctaText) {
    return null;
  }

  return (
    <Dialog
      isOpen={isOpen}
      ariaLabel={modalAriaLabel}
      role="dialog"
      autoFocus
      className={classNames("dialog-modal", className)}
      contentClassName="tl-dialog-content">
      {imageSrc && (
        <div className="imageContainer">
          <div className="imageContainer__overlay" />
          <Image
            src={imageSrc}
            alt={imageAlt}
            layout="responsive"
            objectFit="cover"
            height={3}
            width={5}
            className="imageModal"
            loader={imageLoader}
          />
          {imageText && (
            <h2 className="imageContainer__text has-text-weight-normal is-family-secondary has-text-centered">
              {imageText}
            </h2>
          )}
        </div>
      )}
      <div className="successModal mt-5 mb-6">
        <div className="successModalGrid">
          <FaCheckCircle size={29} className="mb-3 successModal__check-circle" />
          {subtitle && (
            <h6 className="subtitle is-spaced is-uppercase is-family-primary has-text-weight-semibold has-text-centered is-letter-spacing-1 mb-3 successModal__subtitle">
              {subtitle}
            </h6>
          )}
          <h3 className="title is-3 is-spaced is-family-secondary has-text-weight-normal has-text-centered mb-3 successModal__title">
            {title}
          </h3>
          {getInTouchText && (
            <h6 className="subtitle is-6 is-family-primary has-text-centered is-spaced successModal__get-in-touch-text mb-5">
              <Trans>{getInTouchText}</Trans>
            </h6>
          )}
          <Button
            element="button"
            colour="teal"
            onClick={onClose}
            data-tracking-id={dataTrackingId}>
            {ctaText}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
