import React, { useState, useMemo, useRef } from "react";
import { Icon, Icomoon } from "../..";
import { useClickOutsideHook } from "../../utils";
import { site1Api as api } from "@travellocal/utils";

import "./CurrencySelector.scss";

export interface CurrencySelectorComponentProps {
  /** The active currency. */
  value: string | null;

  /** List of available currencies. */
  currencies: api.Currency[];

  onSelect: (currency: api.Currency) => void;
}

/**
 * Component to display the selected currency, and allow it to be changed via a drop-down list.
 *
 * @deprecated Site 1 will be closed down soon, this component should be deleted.
 */
export const CurrencySelectorComponent: React.FC<
  CurrencySelectorComponentProps
> = (props) => {
  const [visible, setVisible] = useState<boolean>(false);

  const listRef = useRef<HTMLDivElement>(null);
  useClickOutsideHook(listRef, () => setVisible(false));

  const { value = "GBP", currencies = [] } = props;
  const currency = useMemo(
    () => currencies.find((c) => c.code === value),
    [value, currencies]
  );

  return (
    <div className="currency-list-wrapper" onClick={() => setVisible(!visible)}>
      <div className="currency-list-header p-2">
        {currency && (
          <img
            className="currency-flag"
            src={`https://resources.travellocal.com/flags/${currency.code}.png`}
            alt={currency.name}
          />
        )}
        <span className="currency-short-name">
          {currency ? currency.code : ""}
        </span>
        <Icon className="p-left-xxsmall" icomoon={Icomoon.arrowDown3} />
      </div>

      {visible && (
        <div className="currency-list" ref={listRef}>
          {currencies.length !== 0 &&
            currencies.map((c) => (
              <div
                key={`currency_${c.id}`}
                onClick={() => props.onSelect(c)}
                className="p-2"
              >
                <img
                  className="currency-flag"
                  src={`https://resources.travellocal.com/flags/${c.code}.png`}
                  alt={c.name}
                />{" "}
                {c.code}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

CurrencySelectorComponent.displayName = "CurrencySelector";
