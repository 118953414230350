import classNames from "classnames";
import React from "react";
import Image from "next/legacy/image";
import imageLoader from "../../utils/imageLoader";
import isChromatic from "chromatic/isChromatic";

import "./HeroSection.scss";
import { useWindowDimensions } from "../../utils";

export interface Props {
  className?: string;
  children?: React.ReactNode;
  imageSrc: string;
  imageMeta: string;
  videoSrc?: string;
}

/**
 * Displays a hero image with optional children
 *
 * On mobile, children can be made to wrap underneath the hero image with `.wrap-children`
 *
 * Styles overwritten/extended throughout the frontend codebase using `.hero-section`
 *
 * @status development
 */

export const HeroSection: React.FC<Props> = ({
  children,
  className,
  imageSrc,
  imageMeta,
  videoSrc
}: Props) => {
  const { isMobile } = useWindowDimensions();
  const hideVideo = (isMobile || isChromatic()) || !videoSrc;

  const getVideoType = (videoSrc?: string): string | undefined => {
    if (!videoSrc) {
      return undefined;
    }

    return `video/${videoSrc.split('.').pop()?.toLowerCase()}`;
  }

  return (
    <div className={classNames("hero-section", className)}>
      <div className="hero-section__bg">
        {imageSrc && hideVideo && (
          <Image
            src={imageSrc}
            alt={imageMeta}
            layout="fill"
            objectFit="cover"
            loader={imageLoader}
            sizes="90vw"
            quality="50"
            priority
          />
        )}
        {
          !hideVideo && (
            <video
              autoPlay
              loop
              muted
              className={classNames("hero-section__bgmedia")}
            >
              <source
                src={videoSrc}
                type={getVideoType(videoSrc)}
              />
            </video>
          )
        }
      </div>
      {children && (
        <div className={classNames("hero-section__fg container")}>
          {children}
        </div>
      )}
    </div>
  );
};
