import { AppState } from ".";
import { setCookie } from "nookies";
import { site1Api, Locale } from "@travellocal/utils";
import { CookieKeys } from "./AppState";

type Action =
  | {
      type: "set_cookie";
      name: CookieKeys;
      value: string;
      options?: { maxAge?: number; path?: string };
    }
  | { type: "load_cookies"; cookies: Partial<Record<string, string>> }
  | { type: "load_currencies"; currencies: site1Api.Currency[] }
  | { type: "set_state"; values: Partial<AppState> };

export const syncCookieProps = (state: AppState): AppState => {
  const preferredLocale =
    (state.cookies?.preferredLanguage as Locale) ?? state.preferredLocale;
  const detectedLocale =
    (state.cookies?.detectedLanguage as Locale) ?? state.detectedLocale;
  return {
    ...state,
    userCurrency: state.cookies?.user_currency ?? state.userCurrency,
    preferredLocale,
    detectedLocale,
  };
};

export const AppStateReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case "load_currencies": {
      return {
        ...state,
        currencies: action.currencies,
      };
    }
    case "load_cookies": {
      const cookies = {
        ...(state.cookies ?? {}),
        ...(action.cookies ?? {}),
      };

      return syncCookieProps({ ...state, cookies });
    }
    case "set_cookie": {
      const { name, value, options = {} } = action;
      setCookie(null, name, value, {
        maxAge: 365 * 24 * 60 * 60,
        path: "/",
        ...options,
      });
      console.info(`Set cookie ${name} to ${value} (${options})`);

      return syncCookieProps({
        ...state,
        cookies: {
          ...(state.cookies ?? {}),
          [name]: value,
        },
      });
    }
    case "set_state": {
      return {
        ...state,
        ...action.values,
      };
    }
  }
};
