import classnames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface Props
  extends Pick<
  React.ImgHTMLAttributes<HTMLImageElement>,
  "alt" | "className" | "height" | "width"
  > {
  type: "light" | "dark" | "colour";
  dataTrackingId?: string;
}

const defaultAlt = "TravelLocal reviews at Reviews.io";

/**
 * The Reviews.io logo.
 *
 * @status stable
 */
export const ReviewsLogo: React.FC<Props> = ({
  type,
  alt,
  className,
  dataTrackingId,
  ...rest
}) => {
  const { t, i18n } = useTranslation("ui");

  const imgClassName = classnames(
    "lazyload",
    "reviewsio-logo",
    `reviewsio-logo--${type}`,
    { "": type === "light" },
    className
  );

  const altText = useMemo(
    () => alt ?? t("ReviewsLogo_alt", defaultAlt),
    [alt, i18n?.language]
  );

  const path = `https://resources.travellocal.com/images/reviewio_logo_${type}.svg`;

  return (
    <img
      {...rest}
      className={imgClassName}
      alt={altText}
      title={altText}
      src={path}
      data-tracking-id={dataTrackingId}
    />
  );
};

ReviewsLogo.defaultProps = {
  alt: defaultAlt,
};
