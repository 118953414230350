/* eslint-disable */

import classnames from "classnames";
import React, { memo } from "react";
import { useAppState } from "../../utils";
import { useTranslation } from "react-i18next";
import { Locale } from "@travellocal/utils";
import {
  AbtaLogo,
  AstaLogo,
  AtolLogo,
  DrvLogo,
  Icomoon,
  Icon,
  ReviewsLogo,
  TravelifeLogo,
} from "../../atoms";
import { StarRating } from "../../molecules";

export const trustCountries = ["en-gb", "en", "de"];
interface TrustProps {
  language?: Locale;
  dataTrackingIds?: {
    travelife?: string;
    reviews?: string;
  };
}

import "./Trust.scss";

export const Trust = ({ language, dataTrackingIds }: TrustProps) => {
  const { t } = useTranslation("ui");
  const { appState } = useAppState();
  const locale = language || appState?.locale || "en-gb";

  const TrustLogo = memo(() => {
    switch (locale) {
      case "en":
        return (
          <>
            <AstaLogo type="dark" className="trust__asta-logo" />
            <a
              href="https://reimagine.travellocal.com/en/travelife"
              data-tracking-id={dataTrackingIds?.travelife}>
              <TravelifeLogo type="dark" className="trust__travelife-logo" />
            </a>
          </>
        );
      case "en-gb":
        return (
          <>
            <div className={classnames("trust__ukLogos", "is-flex", "is-align-items-center")}>
              <AbtaLogo type="dark" className="trust__abta-logo " />
              <AtolLogo type="dark" className="trust__atol-logo" />
              <a
                href="https://reimagine.travellocal.com/en/travelife"
                data-tracking-id={dataTrackingIds?.travelife}>
                <TravelifeLogo type="dark" className="trust__travelife-logo" />
              </a>
            </div>
            <div className="trust__separator"></div>
            <div className="trust__reviewsio has-text-centered has-text-left-desktop">
              <ReviewsLogo type="dark" />
              <div className="mt-2 has-text-elephant">99% of reviewers recommend us</div>
              <div className="mt-1">
                <StarRating currentValue={4.5} maximumValue={5} />
                <a
                  href="https://www.reviews.co.uk/company-reviews/store/travellocalcom"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="reviews inline-block ml-3"
                  data-tracking-id={dataTrackingIds?.reviews}>
                  Read reviews <Icon icomoon={Icomoon.newTab} />
                </a>
              </div>
            </div>
          </>
        );
      case "de":
        return (
          <>
            <DrvLogo type="dark" className="trust__drv-logo" />
            <TravelifeLogo
              type="dark"
              className="trust__travelife-logo"
              dataTrackingId={dataTrackingIds?.travelife}
            />
          </>
        );
      default:
        return null;
    }
  });

  if (!trustCountries.includes(locale)) return null;

  return (
    <div className={classnames("trust", "p-5 p-6-desktop")}>
      <div
        className={classnames(
          "trust__inner",
          "is-flex",
          "is-justify-content-center",
          "is-align-items-center"
        )}>
        <div
          className={classnames(
            "subtitle is-uppercase is-family-primary is-letter-spacing-1 is-size-5 has-text-centered"
          )}>
          {t("Trust_title", "Book with confidence")}
        </div>
        <TrustLogo />
      </div>
    </div>
  );
};

export default Trust;
