import classNames from "classnames";
import React, { useCallback } from "react";
import { site1Api as api, WebRoutes } from "@travellocal/utils";
import { BlogCard } from "../BlogCard/BlogCard";
import { parseISO } from "date-fns";
import "./CardContainer.scss";
import { CollectionCard } from "../CollectionCard/CollectionCard";
import { ReviewCard } from "../ReviewCard/ReviewCard";
import { TripIdeaCard } from "../TripIdeaCard/TripIdeaCard";

export interface CardContainerComponentProps {
  content: api.Content[] | null;
  /**
   * Key used to shard content pages in the redux store.
   * Each separate place CardContainer is used should use a different collectionKey.
   * TODO this should be renamed to "slots key".
   */
  collectionKey: string;

  /**
   * Parameters to pass to the Inspiration API when fetching content for this CardContainer.
   */
  filters?: api.ListContentParams;

  /**
   * The maximum number of cards to show.
   */
  cardLimit?: number;

  /**
   * If set, the card for this piece of content will not be shown if it is returned in the results from the Inspiration API.
   */
  seedItem?: api.Content;

  /**
   * Function to determine whether a card should have isHero true or not.
   */
  isCardHero?: (index: number, cardCount: number) => boolean;

  /**
   * Classname for the .card-container wrapper.
   */
  className?: string;

  /**
   * Classname for the .content-cards within.
   */
  cardClassName?: string;

  /**
   * If defined, makes the card container use Bulma columns.
   * Wraps each card and child element in a column with the provided class.
   */
  columnClassName?: string | ((index: number) => string);

  /**
   * Provide an alternative wrapper around children.
   */
  renderCardWrapper?: (elements: React.ReactNode) => React.ReactNode;

  /**
   * If set to false, nested `.column` divs sit on one line
   */
  isMultineColumn?: boolean;

  /**
   * Number alters focus state of links within content cards, and if/where it participates in sequential keyboard navigation.
   */
  tabIndex?: number;

  /**
   * Set to `true` if parent component is loading content
   */
  isLoading?: boolean;

  onClick?: () => void;
}

type Content = api.Review | api.Article | api.ContentCollection | api.TripIdea;

const ContentCard = ({ content, isHero }: { content: Content; isHero: boolean }) => {
  switch (content.type) {
    case api.ContentType.Article: {
      return (
        <BlogCard
          key={content.id}
          image={content.heroImage?.largeUrl}
          title={content.title}
          date={content.publishedTimestamp ? parseISO(content.publishedTimestamp.value) : undefined}
          url={WebRoutes.contentDetail(content)}
          target="_self"
        />
      );
    }
    case api.ContentType.Review: {
      return (
        <ReviewCard
          key={content.id}
          review={{
            title: content.title,
            quote: content.meta.descriptionTag,
            rating: content.content.overallRating,
            author: `${content.author.firstName}`,
          }}
        />
      );
    }
    case api.ContentType.SampleItinerary: {
      return (
        <TripIdeaCard
          key={content.id}
          tripIdea={content as api.TripIdea}
          heroImageUrl={content.heroImage?.largeUrl}
          heroImageMeta={content.heroImage?.meta}
          url={WebRoutes.contentDetail(content)}
        />
      );
    }
    case api.ContentType.Collection: {
      return (
        <CollectionCard
          key={content.id}
          title={content.title}
          heroImageUrl={content.heroImage?.smallUrl}
          heroImageMeta={content.heroImage?.meta}
          subtitle={(content as api.ContentCollection).content.subtitle}
          url={WebRoutes.contentDetail(content)}
          isHero={isHero ?? false}
        />
      );
    }
  }

  return null;
};

/**
 * Container for inspiration cards
 *
 * @deprecated This probably won't exist in Site 3
 * @status deprecated use CardLayout instead, and add cards directly as children
 */
export const CardContainerComponent: React.FC<CardContainerComponentProps> = (props) => {
  const {
    content,
    seedItem,
    className,
    columnClassName,
    isCardHero,
    cardLimit,
    renderCardWrapper,
    isMultineColumn,
    isLoading,
  } = props;

  // Use Bulma columns if columnClassName is defined.
  const useColumns = columnClassName != null;

  // Column class name is configurable based on index.
  const getColumnClassName = (i: number) =>
    typeof columnClassName === "function" ? columnClassName(i) : columnClassName;

  // If useColumns, each card needs to be wrapped.
  const OptionalColumn = useCallback(
    ({ index, children }: { index: number; children: React.ReactNode }) => {
      if (useColumns) {
        return <div className={classNames("column", getColumnClassName(index))}>{children}</div>;
      } else {
        return <>{children}</>;
      }
    },
    [useColumns]
  );

  const containerClass = classNames("card-container", className, {
    columns: useColumns,
    "is-multiline": isMultineColumn,
  });

  const data = ((isLoading ? [] : content) ?? [])
    .filter((c) => c.id != seedItem?.id)
    .slice(0, cardLimit);

  const cards = data.map((content, index) => {
    return (
      <OptionalColumn index={index} key={content.id}>
        <ContentCard
          content={content as Content}
          isHero={isCardHero?.(data.length, index) ?? false}
        />
      </OptionalColumn>
    );
  });

  return <div className={containerClass}>{renderCardWrapper?.(cards) ?? cards}</div>;
};

CardContainerComponent.defaultProps = {
  isMultineColumn: true,
  isLoading: false,
};
