/* eslint-disable */

import React from "react";

import classnames from "classnames";
import { isBrowser, site1Api as api, ValidationError } from "@travellocal/utils";

import "./HubspotForm.scss";

interface State {
  subscriptionSending: boolean;
  subscriptionSent: boolean;
  subscriptionErrored: boolean;
  subscriptionMessage?: string;
}

/**
 * @deprecated we're not using Hubspot anymore
 */
export class HubspotForm extends React.Component<{}, State> {
  private inputRef: HTMLInputElement | null = null;

  constructor(props: {}) {
    super(props);
    this.state = {
      subscriptionSending: false,
      subscriptionSent: false,
      subscriptionErrored: false,
    };
  }

  public componentDidUpdate() {
    // Hides sucess message after 8 seconds
    if (this.state.subscriptionSent) {
      setTimeout(() => this.setState({ subscriptionSent: false }), 8000);
    }
  }

  /**
   * Returns a generic error message by default.
   * Returns a email specific message, if there is a email related validation error.
   */
  private parseError(validations: ValidationError[]): string {
    let subscriptionErrorMessage = "There was a problem with sending your form. Please try again.";
    if (validations && validations.length > 0) {
      if (validations.some((e) => e.attribute === "emailAddress")) {
        subscriptionErrorMessage = "Please provide a valid email address.";
      }
    }
    return subscriptionErrorMessage;
  }

  private getPageData(): Pick<api.HubspotNewsletterSubscription, "pageUri" | "pageName"> | null {
    if (!isBrowser()) {
      return null;
    }
    return {
      pageUri: window.location.href || "N/A",
      pageName: document.title || "N/A",
    };
  }

  private subscribe = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const pageData = this.getPageData();

    // If previously sent, set "sent" flag back to false
    this.setState({ subscriptionSending: true, subscriptionSent: false });
    const postData = {
      emailAddress: this.inputRef?.value.trim(),
      ...pageData,
    } as api.HubspotNewsletterSubscription;

    try {
      await api.subscribeToNewsletter(postData);
      const subscriptionMessage = "Thanks for subscribing!";
      this.setState(
        {
          subscriptionSent: true,
          subscriptionErrored: false,
          subscriptionMessage,
          subscriptionSending: false,
        },
        () => {
          if (this.inputRef) {
            this.inputRef.value = "";
          }
        }
      );
    } catch (error) {
      const subscriptionMessage = this.parseError((error as any)?.validations);
      this.setState({
        subscriptionErrored: true,
        subscriptionMessage,
        subscriptionSending: false,
      });
    }
  };

  public render() {
    const buttonText = this.state.subscriptionSending ? "Sending" : "Subscribe";
    const validationBarClass = classnames("validation-bar", {
      success: this.state.subscriptionSent,
    });
    return (
      <div className="hspot-newsletter-form">
        <form
          method="post"
          onSubmit={this.subscribe}
          id=""
          name="hubspot-form"
          className="validate"
          target="_blank"
          noValidate>
          <input
            type="email"
            name="email"
            className="email"
            id=""
            placeholder="Your email..."
            ref={(x) => (this.inputRef = x)}
            disabled={this.state.subscriptionSending}
            aria-label="Enter your email address"
          />
          <button
            type="submit"
            className="primary-button"
            disabled={this.state.subscriptionSending}>
            {buttonText}
          </button>
        </form>

        {(this.state.subscriptionErrored || this.state.subscriptionSent) && (
          <div className={validationBarClass} key={Math.random()}>
            {this.state.subscriptionMessage}
          </div>
        )}
      </div>
    );
  }
}
